import React, { useState, useEffect } from 'react';
import { Image } from 'react-bootstrap';

function DamierComponent() {
    const [text, setText] = useState('');
    const phrases = ["Frontend & Backend Developer", "I design and code ergonomic things, and I love It."];
    const typingDelay = 50;
    const deletingDelay = 30;
    const loopDelay = 2000; // Pause entre les phrases

    useEffect(() => {
        let currentPhraseIndex = 0;
        let charIndex = 0;
        let isDeleting = false;
        let timerId;

        const type = () => {
            setText(phrases[currentPhraseIndex].substring(0, charIndex));
            if (!isDeleting) {
                charIndex++;
                if (charIndex === phrases[currentPhraseIndex].length + 1) {
                    isDeleting = true;
                    timerId = setTimeout(type, loopDelay);
                } else {
                    timerId = setTimeout(type, typingDelay);
                }
            } else {
                charIndex--;
                if (charIndex === 0) {
                    isDeleting = false;
                    currentPhraseIndex = (currentPhraseIndex + 1) % phrases.length;
                    timerId = setTimeout(type, typingDelay);
                } else {
                    timerId = setTimeout(type, deletingDelay);
                }
            }
        };

        type();

        return () => clearTimeout(timerId); // Nettoyage à la désinscription
    }, []);

    return (
        <>
            <div className="container p-3">
                <div className="columns text-center">
                    <div className="column">
                        <h1 className="title is-spaced is-size-1-desktop is-size-2-tablet is-size-3-mobile"  style={{height: '20px'}} >{text}</h1>
                        <Image className="avatar" src="/mf-avatar.png" height={'210px'}/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DamierComponent;
