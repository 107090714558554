import React, { useRef, useEffect } from 'react';

const ParallaxComponent = ({ text }) => {
  const parallaxRef = useRef(null);

  useEffect(() => {
    const parallax = parallaxRef.current;
    let animationFrameID;

    const handleScroll = () => {
      const scrollY = window.scrollY;

      // Appliquer un effet différent pour chaque lettre
      Array.from(parallax.children).forEach((child, index) => {
        const speed = (index % 2 === 0 ? 0.5 : -0.5); // Alternance des vitesses pour chaque lettre
        child.style.transform = `translateY(${scrollY * speed}px) rotateZ(${scrollY * 0.1}deg)`;
        
      });

      animationFrameID = window.requestAnimationFrame(handleScroll);
    };

    handleScroll();

    return () => {
      window.cancelAnimationFrame(animationFrameID);
    };
  }, []);

  return (
    <div style={{  paddingTop: '40vh', paddingBottom: '50vh',background: 'rgb(255, 216, 216)'    }}>
    <div style={{ textAlign: 'center'}} ref={parallaxRef}>
      {text.split('').map((letter, index) => (
        <span className="font-link"key={index} style={{justifyItems: 'center',display: 'inline-block', fontSize: '5.2rem' , fontFamily: "'Anton', sans-serif"}}>{letter}</span>
      ))}
    </div>
    </div>
  );
};

export default ParallaxComponent;
