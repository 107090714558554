import NavbarComponent from './components/NavbarComponent.js';
import GameComponent from './components/GameComponent.js';
import ParralaxComponent from './components/ParallaxComponent.js';
import LanguageComponent from './components/LanguageComponent.js';
import DamierComponent from './components/DamierComponent.js';
import ProjectComponent from './components/ProjectComponent.js';

import 'bootstrap/dist/css/bootstrap.min.css';
import Checkerboard1 from './components/Checkerboard1';
import Reseau from './components/ReseauComponent';
function App() {
  return (
    <>
   <ParralaxComponent text="REZKI" ></ParralaxComponent>
   <NavbarComponent></NavbarComponent>
   <DamierComponent></DamierComponent>
   <Checkerboard1></Checkerboard1>
   <LanguageComponent></LanguageComponent>
   <Reseau></Reseau>
   <GameComponent></GameComponent>
   <ProjectComponent></ProjectComponent>
   <footer class="bg-primary text-light text-center py-3">
    <p > 2024 Bessahli Rezki. Tous droits réservés.</p>
  </footer>
   </>
  );
}


export default App;
