import "./Project.css" 
import React, { useState } from "react";

function ProjectComponent() {

  
  const CompetencesDeroulant = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
      setIsOpen(!isOpen);
    };

    return (
      <div className="Project-but" >
        <h2 onClick={toggleAccordion}  className="Project-H2" >
          Compétences travaillées lors du projet {isOpen ? "▲" : "▼"}
        </h2>
        
        {isOpen && (
          <ul style={{ paddingLeft: "20px", lineHeight: "1.8" }}>
            <li>Rédiger un Cahier Des Charges</li>
            <li>Rédiger des spécifications techniques</li>
            <li>Réaliser une maquette</li>
            <li>Identifier les fonctionnalités à développer</li>
            <li>Déployer un environnement de travail</li>
            <li>Rédiger une présentation</li>
            <li>Rédiger le code de la solution</li>
            <li>Rédiger une documentation utilisateur</li>
            <li>Implémenter la partie 'front-end'</li>
            <li>Implémenter la logique et la base de données</li>
            <li>Implémenter des règles d'authentification</li>
            <li>Analyser la qualité de l’ergonomie et la qualité de l’accessibilité</li>
            <li>Développer le prototype</li>
          </ul>
        )}
      </div>
    );
  };


  const CompetencesDeroulant2 = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
      setIsOpen(!isOpen);
    };

    return (
      <div className="Project-but" >
        <h2 onClick={toggleAccordion}  className="Project-H2" >
          Compétences travaillées lors du projet {isOpen ? "▲" : "▼"}
        </h2>
        
        {isOpen && (
          <ul style={{ paddingLeft: "20px", lineHeight: "1.8" }}>
           <li>Déployer un environnement de travail</li>
<li>Rédiger une présentation</li>
<li>Intégrer les différents éléments</li>
<li>Implémenter la partie 'front-end'</li>
<li>Implémenter un plan de tests</li>
<li>Identifier des améliorations qualitatives et de performance</li>
<li>Analyser la qualité de l’ergonomie et la qualité de l’accessibilité</li>

          </ul>
        )}
      </div>
    );
  };


  const CompetencesDeroulant3 = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
      setIsOpen(!isOpen);
    };

    return (
      <div className="Project-but" >
        <h2 onClick={toggleAccordion}  className="Project-H2" >
          Compétences travaillées lors du projet {isOpen ? "▲" : "▼"}
        </h2>
        
        {isOpen && (
          <ul style={{ paddingLeft: "20px", lineHeight: "1.8" }}>
         <ul>
  <li>Déployer un environnement de développement en PHP</li>
  <li>Organiser et structurer un dépôt pour le projet</li>
  <li>Élaborer des algorithmes de tri adaptés</li>
  <li>Implémenter des opérations de manipulation de listes</li>
  <li>Rédiger un fichier exécutable principal (`push_swap.php`)</li>
  <li>Ajouter des fonctionnalités bonus dans un sous-dossier spécifique</li>
  <li>Optimiser le code pour une meilleure performance</li>
  <li>Écrire des tests pour valider le comportement des opérations</li>
  <li>Analyser la qualité de l'ergonomie et l'accessibilité des sorties</li>
</ul>


          </ul>
        )}
      </div>
    );
  };
  
  return (<>
    <div id="projets" >
    
      <p class="project-img-p" >
        <p><h1>Projet E-commerce <br></br> ( 4 personnes )</h1> </p>
       <img class="project-img" src="./projet-ecommerce.png"></img>
       <CompetencesDeroulant />

       </p>
       
      </div>
        <div>

        <p class="project-img-p2" >
          <p><h1>Projet Spotify <br></br> ( 3 personnes 72h)</h1> </p>
         <img class="project-img" src="./project-spotify.png"></img>
         <CompetencesDeroulant2 />
  
         </p>
         
      </div>
      <div>

      <p class="project-img-p" >
        <p><h1>Projet Push-Swap <br></br> ( Solo )</h1> </p>
       <img class="project-img" src="./project-push-swap.png"></img>
       <CompetencesDeroulant3 />

       </p>
       
      </div>
<section id="contact">
      <div className="contact-mail"><p className="contact-mail-p" ><a href="mailto:bouzshot34@gmail.com"><h2>Me contacter par mail 📧</h2></a></p></div></section>
      </>
  );
}
export default ProjectComponent;

