import './Checkerboard1.css';
import React from 'react';

const Checkerboard1 = () => {
  return (
    <div className="checkerboard">
      {/* Row 1 */}
      <div className="row row-1 p-lg-5 bg-primary">
        <div className="col-txt col-md-6 pe-lg-5">
          <h2 className='text-center mt-5 '>L'art du Full-Stack</h2>
          <p className='text-center'>De la conception front-end, où l'expérience utilisateur prend vie, à la gestion efficace des bases de données en back-end, chaque étape du processus n'a plus de secret pour moi. Mon savoir-faire polyvalent me permet de créer des solutions intégrées, offrant ainsi une expérience utilisateur fluide et cohérente à chaque instant.</p>
        </div>
        <div className="col-img col-md-6 order-last text-center p-sm-3">
          <img src="/acc-1.png" alt="Image 1" className="rounded-4 img-fluid" />
        </div>
      </div>

      {/* Row 2 */}
      <div className="row row-2 p-lg-5 bg-secondary">
        
      <div className="col-txt col-md-6 pe-lg-5 order-lg-last order-md-last">
          <h2 className='text-center mt-5 '>Logique dans le Développement Full Stack</h2>
          <p className='text-center'>Mon approche du développement réside dans une pensée rigoureuse et ordonnée. La logique guide mes choix, façonnant des solutions informatiques sophistiquées et épurées. Mon engagement envers une approche logique se matérialise à travers des systèmes robustes et une résolution de problèmes efficiente, transformant chaque projet en une expression de ma pensée analytique et rationnelle.</p>
        </div>

        <div className="col-img col-md-6 text-center p-sm-3 ">
          <img src="/acc-2.png" alt="Image 2" className="rounded-4 img-fluid" />
        </div>
       
      </div>

      {/* Row 3 */}
      <div className="row row-3 p-lg-5 bg-primary">
      <div className="col-txt col-md-6 pe-lg-5 ">
          <h2 className='text-center mt-5'>Apprentissage Autonomes</h2>
          <p className='text-center'>En dehors des projets professionnels, je consacre mon temps libre à explorer et maîtriser de nouveaux langages, élargissant ainsi mes horizons technologiques. Cette volonté d'apprendre de manière autonome témoigne de mon engagement envers une croissance continue et d'une adaptabilité sans limites dans le monde en constante évolution de la technologie.</p>
        </div>
        <div className="col-img col-md-6 text-center p-sm-3">
          <img src="/acc-3.png" alt="Image 3" className="rounded-4 img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default Checkerboard1;
