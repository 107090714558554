import "./Language.css" 
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  

function LanguageComponent() {


  return (
    <section id="langages" className="Language-section">
      <h2 className="line-title" >Language maitrisé</h2>
      <OwlCarousel className='owl-theme' center mouseDrag autoWidth items={4} autoplay={true} autoplayTimeout={2500} loop >
        <div className="item"  style={{backgroundImage: 'url(https://www.tekoway.com/wp-content/uploads/2017/05/react-logo.png)'}} >
          <div className="item-desc">
            <h3>React</h3>
          </div>
        </div>
        <div className="item"  style={{backgroundImage: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/HTML5_logo_and_wordmark.svg/1024px-HTML5_logo_and_wordmark.svg.png)'}}>
          <div className="item-desc">
            <h3>HTML</h3>
          </div>
        </div>
        <div className="item"  style={{backgroundImage: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1452px-CSS3_logo_and_wordmark.svg.png)'}} >
          <div className="item-desc">
            <h3>CSS</h3>
          </div>
        </div>
        <div className="item"  style={{backgroundImage: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/9/96/Sass_Logo_Color.svg/2560px-Sass_Logo_Color.svg.png)'}}>
          <div className="item-desc">
            <h3>SCSS</h3>
          </div>
        </div>
        <div className="item"  style={{backgroundImage: 'url(https://quintagroup.com/cms/js/js-image/javascript-logo.png/@@images/8c64c4b9-4e1c-4c26-9b5e-78d85e3130a9.png)'}}>
          <div className="item-desc">
            <h3>JavaScript</h3>
          </div>
        </div>
        <div className="item"  style={{backgroundImage: 'url(https://pngimg.com/d/php_PNG10.png)'}}>
          <div className="item-desc">
            <h3>PHP</h3>
          </div>
        </div>
        <div className="item"  style={{backgroundImage: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/800px-Python-logo-notext.svg.png)'}}>
          <div className="item-desc">
            <h3>Python</h3>
          </div>
        </div>
      </OwlCarousel>
    </section>
  );
}
export default LanguageComponent;

