import './Reseau.css';
import React from 'react';
import { Image } from 'react-bootstrap';

const Reseau = () => {
  return (
    <>
    <h2 className='text-center'>Mes réseaux</h2>
    <ul class="social-menu" id='contacts'>
    <li class="social-btn facebook" tooltip="Linkedin">
      <a href="https://www.linkedin.com/in/rezki-bessahli-60a688261/" target='_blank'><Image src="link.png"/></a>
    </li>
    <li class="social-btn instagram" tooltip="GitHub">
      <a href="https://github.com/RRK28" target='_blank'><Image src="git.png"/></a>
    </li>
    
  </ul>
  </>
  );
};

export default Reseau;
