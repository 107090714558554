import "./Game.css" 
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  

function GameComponent() {

  const active = (event) => {
    if (event.target.classList.contains('active')) {
      event.target.classList.remove('active');
    }
    else  {
      event.target.classList.add('active');
    }
  };

  const inactive = (event) => {
    if (event.target.classList.contains('active')) {
      event.target.classList.remove('active');
    }
  };

  return (
    <section id="jeux" className="game-section">
      <h2 className="line-title" >Mes jeux favoris  </h2>
      <OwlCarousel className='owl-theme' center mouseDrag autoWidth items={4} autoplay={true} autoplayTimeout={2500} loop >
        <div className="item" onMouseOver={active} onMouseLeave={inactive} style={{backgroundImage: 'url(https://images2.alphacoders.com/474/474206.jpg)'}} >
          <div className="item-desc">
            <h3>Dota 2</h3>
            <p>Dota 2 est un jeu vidéo de type arène de bataille en ligne multijoueur développé et édité par Valve</p>
          </div>
        </div>
        <div className="item" onMouseOver={active} onMouseLeave={inactive} style={{backgroundImage: 'url(https://www.yudiz.com/codepen/expandable-animated-card-slider/winter-3.jpg)'}}>
          <div className="item-desc">
            <h3>The Witcher 3</h3>
            <p>The Witcher 3: Wild Hunt est un jeu vidéo de type action-RPG développé par le studio polonais CD Projekt RED.</p>
          </div>
        </div>
        <div className="item" onMouseOver={active} onMouseLeave={inactive} style={{backgroundImage: 'url(https://www.yudiz.com/codepen/expandable-animated-card-slider/rdr-2.jpg)'}} >
          <div className="item-desc">
            <h3>RDR 2</h3>
            <p>Red Dead Redemption II, également abrégé RDRII ou RDR2, est un jeu vidéo d'action-aventure et de western multiplateforme, développé et édité par Rockstar Games.</p>
          </div>
        </div>
        <div className="item" onMouseOver={active} onMouseLeave={inactive} style={{backgroundImage: 'url(https://ddragon.leagueoflegends.com/cdn/img/champion/splash/Zed_38.jpg)'}}>
          <div className="item-desc">
            <h3>League Of Legends</h3>
            <p>League of Legends est un jeu vidéo sorti en 2009 de type arène de bataille en ligne, free-to-play, développé et édité par Riot Games.</p>
          </div>
        </div>
        <div className="item" onMouseOver={active} onMouseLeave={inactive} style={{backgroundImage: 'url(https://www.yudiz.com/codepen/expandable-animated-card-slider/fortnite.jpg)'}}>
          <div className="item-desc">
            <h3>Fortnite</h3>
            <p>Fortnite est un jeu en ligne développé par Epic Games sous la forme de différents modes de jeu qui partagent le même gameplay général et le même moteur de jeu.</p>
          </div>
        </div>
        <div className="item" onMouseOver={active} onMouseLeave={inactive} style={{backgroundImage: 'url(https://img.redbull.com/images/c_crop,x_1151,y_0,h_2160,w_1620/c_fill,w_450,h_600/q_auto:low,f_auto/redbullcom/2020/6/3/ypiocflf3wcbeeyjyi5x/valorant)'}}>
          <div className="item-desc">
            <h3>Valorant</h3>
            <p>Valorant est un jeu vidéo free-to-play de tir à la première personne en multijoueur développé et édité par Riot Games.</p>
          </div>
        </div>
        <div className="item" onMouseOver={active} onMouseLeave={inactive} style={{backgroundImage: 'url(https://platform.polygon.com/wp-content/uploads/sites/2/chorus/uploads/chorus_asset/file/24380016/Legend_of_Zelda_Ocarina_of_Time_official_art.png?quality=90&strip=all&crop=5,0,90,100)'}}>
          <div className="item-desc">
            <h3>The Legend of Zelda</h3>
            <p>The Legend of Zelda, ou simplement Zelda, est une série de jeux vidéo d'action-aventure produite par la société japonaise Nintendo.</p>
          </div>
        </div>
      </OwlCarousel>
    </section>
  );
}
export default GameComponent;

